// redirect.js
import router from '../Router/index'; // Import your Vue Router instance
import {globalState} from '../store/globalState'; 
export default function redirectTo(path) {

  globalState.isMenuOpen = false;
  router.push(path).catch(err => {
    if (err.name != 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
      // Handle any errors here
      console.error(err);
    }
  });
}


export function convertToTimeZoneAndFormat(value) {
  if (value) {
    const date = new Date(value); 
    
    date.setHours(date.getHours() + 8);
    return date.toLocaleDateString('en-US', {
      year: 'numeric', month: 'short', day: 'numeric',
      hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true
    });
  } else {
    return '-'
  }
}

export function formatNumber(number) {
  return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  }).format(number);
}
