<template>
    <div class="page-wrapper" >
        <header style="background-image: url('/images/bg/header.jpg');    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover">
            <!-- NAV TOP -->
            <div class="nav-top" >
                
                <div class="container">
                   
                    <!-- COLLAPSE -->
                    <div class=" navbar-collapse" id="top-menu">
                        <ul class="menu-inline navbar-left">
                            <li class="lang">
                                <ul class="list-inline">
                                    <li><a href="#" @click="changeLanguage('en-US')">EN</a></li>
                                    <li><a href="#" @click="changeLanguage('zh-TW')">HK</a></li>
                                </ul>
                            </li>
                        </ul>
                        <ul class="menu-inline navbar-right">
                            
                            <li>{{ $t('header_1') }} : <a  class="text-color" href="#">   064</a></li>
                            <li>{{ $t('header_2') }} : <a class="text-color" href="#">A-B-23-07-00810</a></li>
                        </ul>
                    </div>
                </div><!-- ./ CONTAINER -->
            </div>
            <HeaderMenu />
        </header>

        <router-view />
    <FooterNav />
</div></template>

<script setup>

import HeaderMenu from './HeaderMenu.vue';
import FooterNav from './FooterNav.vue';

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
const currentLocale = ref(locale.value);

function changeLanguage(newLocale) {
    locale.value = newLocale;
    currentLocale.value = newLocale;
}

document.title = 'Gold Fun – Hong Kong Gold Trading Broker';
</script>

<style>
.nav-top {
    background-color: #333;
    line-height: 45px;
    z-index: 9999999;
    position: relative;
}</style>