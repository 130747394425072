export default {
  "article_title_1": "金豐來推比特幣1安士9999純金紀念幣 料金價2,000-2,600美元上落",
  "article_title_2": "黄金实时行情分析：黄金价格逐渐走高 多头将瞄准去年12月28日高点2088美元",
  "article_title_3": "黄金TD开启小幅涨势 聚焦鲍威尔的讲话",
  "article_title_4": "美联储保持利率不变 黄金TD开启小幅涨势",
  "article_title_5": "经济数据发布黄金缓涨",
  "article_title_6": "金市展望：多头幸存下来！非农和美联储冲击黄金 这一危机恐引爆涨势",
  "article_1_1": "金银业贸易场行政总裁冯炜能(右二)出席支持活动，并与金丰来有限公司负责人张明德(左二)及一众来宾合照。 <br/> <br/>  金丰来日前举办「2024比特币1安士9999纯金纪念币」上市发布会，该主题是「黄金有价，历史无价」。一枚纪念币定价约为2,500美元，折合约2万元，届时将根据金价作出调整。对于金价未来一季的预测，金丰来负责人张明德预计，未来数个月内金价每安士料介乎2000-2600美元，或达到2,800美元。",
  "article_1_2": "\n\n该产品为9999纯金铸造，每枚净重一金衡安士，这也标志比特币铭文纪念品的诞生，售附NFT参与全新GameFi项目，且购买清单将以比特币铭文纪录。每一枚币上都有独特的数字，目前较好的数字已被订完。 <br/>\n\n张明德表示，此次纪念币的发布意义非凡，2024年标着着比特币的三大里程：1）庆祝发行15周年；2）美国现货ETF获批，流入传统市场；3）比特币迎来第四次产量减半。 <br/>\n\n当日会上举办慈善义卖，一枚币以2 万元起拍，有幸运儿即场以2.68 万元投得此具有非凡意义的纪念币。 <br/>",
  "article_1_3": "<img data-v-f385ed7c=\"\" src=\"/images/article-1-1.jpeg\" class=\"img-responsive\" alt=\"post-img\">",
  "article_1_4": "\n<br/>\n============= <br/>\n深入阅读政经生活文化，更多内容尽在：<br/>\nWebsite：<a href=\"https://www.capital-hk.com\" target=\"_blank\">www.capital-hk.com</a> <br/>\r\nFacebook：<a href=\"https://www.facebook.com/CapitalPlatformHK\" target=\"_blank\">www.facebook.com/CapitalPlatformHK</a> <br/>\r\nInstagram：<a href=\"https://www.instagram.com/capital.ceo.entrepreneur\" target=\"_blank\">www.instagram.com/capital.ceo.entrepreneur</a> <br/>\r\nLinkedIn：<a href=\"https://www.linkedin.com/company/capitalhk/\" target=\"_blank\">www.linkedin.com/company/capitalhk/</a> <br/>\n",
  "article_2_1": "周五（2月2日），现货黄金价格交投于2056美元盎司附近，黄金价格逐渐走高，此前在交易日早些时候触及2065美元/盎司，为自1月3日以来的最高水平，接近一个月来的高点。",
  "great_comment": "精彩评论",
  "no_comment": "暂时没有评论哦~",
  "write_comment": "写评论",
  "info_sharing": "资讯分享",
  "likes": "赞",
  "brand_name": "金丰来",
  "read": "阅读",
  "disclaimer": "免责申明:",
  "footer_disclaimer": " 本网站上的资讯可能会向您介绍由金丰来有限公司 Gold Fun Corporation Limited 或其任何 附属或关联公司(统称「GF Corp」)提供的某些贵金属投资相关产品和服务。网站上发布的任何资讯均不构成进行任何交易的招揽、要约或建议。这些网页中引用的产品、服务、资讯和/或资料可能不适用于某些司法管辖区(包括但不限于美国、加拿大、日本)的所有  个人或居民。在造访这些网页中引用的产品、服务、资讯和/或资料之前,请确保您不受所在司法管辖区的法律限制。",
  "header_1": "香港金银业贸易场行员",
  "header_2": "香港海关注册号码",
  "flexible_msg": "买卖灵活,成就未来",
  "go_to_one_like": "前往 OneLike Shop",
  "go_to_sampattisafar": "前往 SampattiSafar",
  "about_gold_fun": "关于金丰来",
  "about_gold_fun_1": " 金丰来有限公司(Gold Fun Corporation Ltd),成立于2016年,是香港金银业贸易场正式行员(行员编号:064,营业状况类别:B),亦是经香港海关注册的 A 类注册商。一直专注于贵金属业务,我们提供一站式全方位服务,包括黄金的精炼、生产、贸易、投资,以及市场行销及价位对冲等。从金矿源头到纯金制品,从押运仓存到投资对冲,我们的专业团队及顾问皆能为客户量身订做各类贵金属贸易及投资方案,安全可靠。",
  "about_gold_fun_2": " 近年来,我们在香港与多家领先的熔炼服务公司建立业务合作,日均处理量大增,交收快捷妥当。展望未来,金丰来会根据市场最新趋势,致力于改善业务结构并把握新兴产业的机遇。我们积极拓展本地及国际市场,寻找可靠的合作伙伴,全面推动公司发展。我们的团队以诚实、积极和专业的态度,为客户提供稳定、高效且快速的服务,应对日新月异的国际贵金属市场。",
  "local_melting_service": "本地熔炼服务",
  "local_melting_msg": "透过合作伙伴,为海外及本地黄金货源提供一站式熔炼服务,包括押运、清关、仓存、交割",
  "hedge_service": "对冲服务",
  "hedge_service_msg": "透过「丰来金」交易,为实金客户提供对冲服务,交易简易灵活,减低价格变动风险",
  "solid_gold_sale": "实金买卖",
  "solid_gold_sale_msg": "千足纯金、公斤条、品牌条,一应俱全,交易量大,交收快捷安全",
  "gfgold_msg": "杠杆型合约投资产品,短线投资、实金对冲,费率特低,灵活易用",
  "about_gold_fun_detail": "金丰来有限公司(Gold Fun Corporation Ltd),成立于2016年,是香港金银业贸易场正式行员(行员编号:064,营业状况类别:B),亦是经香港海关注册的 A 类注册商。一直专注于贵金属业务,我们提供一站式全方位服务,包括黄金的精炼、生产、贸易、投资,以及市场行销及价位对冲等。从金矿源头到纯金制品,从押运仓存到投资对冲,我们的专业团队及顾问皆能为客户量身订做各类贵金属贸易及投资方案,安全可靠。",
  "click_to_see": "点击查看更多",
  "login": "交易账户 （登录 / 开户)",
  "more": "了解更多",
  "latest_news": "最新资讯",
  "column_analysis": "专栏分析",
  "analysis_title_1": "2024-02-02 成报专栏《真金白银》—中东局势恶化 黄金避险",
  "analysis_title_2": "2024-01-26 成报专栏《真金白银》—PMI 数据强劲 金价走低",
  "analysis_title_3": "2024-01-19 成报专栏《真金白银》—美零售数据强劲 金价下滑",
  "analysis_title_4": "2024-01-12 成报专栏《真金白银》—美国CPI来袭 金价暂稳据",
  "service_subtitle_1": "专家重熔服务可重新利用您的黄金。",
  "service_subtitle_2": "精致的钻石珠宝，永恒的优雅。",
  "service_subtitle_3": "适合严肃投资者的高品质 12 公斤金条。",
  "service_subtitle_4": "原始的 24 克拉金条，适合安全投资。",
  "service_subtitle_5": "用于即时黄金交易分析的先进软体。",
  "service_subtitle_6": "未来的黄金合约可确保您的投资安全。",
  "service_title_1": "熔炼服务",
  "service_title_2": "钻石首饰",
  "service_title_3": "12公斤金块",
  "service_title_4": "纯金金条",
  "service_title_5": "即时交易软件",
  "service_title_6": "黄金期货",
  "core_services": "核心服务",
  "welcome_to_goldfun": "欢迎来到金丰来",
  "banner_msg_1": "投资确定性，黄金就是未来",
  "personal_consultant": "个人黄金投资顾问",
  "banner_msg_2": "有黄金投资问题吗？",
  "finanance_strength": "资金实力是我们成功的基石",
  "our_services": " 我们的服务",
  "contact_us": "联络我们",
  "learn_more": "了解更多",
  "address": "地址",
  "contact": "联络",
  "opening_time": "营业时间",
  "email": "电子邮件",
  "view_all_news": "查看所有新闻",
  "onelike_desc": "OneLike Shop 是与金丰来合作的网上商城，商城内的贵金属制品、翡翠玉器、美钻首饰、金条金砖等，均由金丰来全力提供，货真价实，应有尽有，优质保证。",
  "sampatti_desc": "SampattiSafar由Gold Fun Corporation Ltd开发，是一个专为印度市场量身定制的平台，旨在促进贵金属交易，利用国际定价标准来保护利益。它透过整合本地支付网关进行 24/7 交易，简化了投资流程，允许投资低至 200 印度卢比，并具有自动机制，可最大限度地减少损失并确保利润。专为方便、高效而设计，提供快速结算和轻松帐户管理，让用户更轻松地从事贵金属交易，以最小的障碍实现财务目标。",
  "gfgold_desc": "这项金融服务具有显著的优势，允许投资者透过杠杆黄金/白银合约以最小的努力实现实质成果，仅需要 2% 的最低保证金。它提供交易日24小时交易的便利，使交易者能够抓住每一个机会。此外，该服务还拥有极低的交易费用，并在达到一定数量后提供免佣金交易的福利。此外，它还提供灵活的结算选项，允许以现金或实体黄金结算，满足短期投资策略和实体对冲需求。对于那些希望最大限度地提高投资效率和灵活性的人来说，这种功能组合使其成为一个有吸引力的选择。",
  "business_associates": "商业伙伴",
  "service_title_7": "仓储服务",
  "service_title_8": "量身订制的交易",
  "service_title_9": "GF 黄金贸易",
  "service_title_10": "黄金投资",
  "service_title_11": "矿山中的金矿石",
  "service_subtitle_7": "贵重物品的安全储存解决方案",
  "service_subtitle_8": "贵金属个性化交易计划",
  "service_subtitle_9": "GF黄金交易，专为精英投资者",
  "service_subtitle_10": "全面黄金投资服务与咨询",
  "service_subtitle_11": "直接获取知名矿山的优质金矿石",
  "home": "首页",
  "hedging_faq": "对冲 FAQ",
  "hedging_faq_title": "黄金对冲服务由",
  "what_is_hedging": "什么是「对冲」？",
  "hedging_msg_1": "对冲是一种风险管理策略，用于减少\r\n                             金融市场价格波动的影响。它涉及采取抵消立场\r\n                             与原始头寸负相关的金融工具，从而\r\n                             减少市场波动带来的潜在损失。对冲的目标是尽量减少\r\n                             市场风险对投资组合的影响，确保任何不利的价格变动\r\n                             一种产品的收益部分或全部被另一种产品的收益所抵销。",
  "hedging_msg_2": "身为实体黄金交易者，如何\r\n                                     您使用 Gold Fun 的交易帐户进行避险吗？",
  "hedging_msg_3": "实体黄金交易者可以使用交易帐户进行对冲\r\n                             他们透过在黄金合约中持有抵销头寸来承受黄金价格的风险。",
  "hedging_msg_4": "为了\r\n                                         购买黄金并等待交割的人：",
  "hedging_msg_5": "如果交易者做多实体黄金并担心\r\n                             对于潜在的价格下跌，他们可以出售黄金合约以锁定销售价格\r\n                             他们的实物资产。如果黄金价格确实下跌，其实物损失\r\n                             黄金持有量将被其合约部位的收益所抵销。",
  "hedging_msg_6": "为了\r\n                                         卖出产品后需要购买黄金作为原料的：",
  "hedging_msg_7": "如果交易者做空实体黄金并担心\r\n                             对于潜在的价格上涨，他们可以购买黄金合约以限制潜在的损失。\r\n                             使用交易帐户进行避险可以帮助交易者管理风险并锁定\r\n                             利润。",
  "hedging_msg_8": "对冲的最佳时机是什么以及什么时候是最好的\r\n                                     清算我的对冲部位？",
  "hedging_msg_9": "对冲通常用于管理风险，因此\r\n                             对冲决定应基于对市场环境和投资者的评估\r\n                             对未来价格走势的看法，但通常情况下，一旦客户暴露，就应该这样做\r\n                             价格变动的风险。",
  "hedging_msg_10": "对于避险部位的平仓，最佳时机是\r\n                             当原始部位不再需要市场风险保护时，或者\r\n                             当市场条件改变以致不再需要或不再需要对冲时\r\n                             有效管理风险。",
  "hedging_msg_11": "使用停损单或其他风险管理\r\n                             策略也可能适合帮助管理风险并确保及时退出对冲，\r\n                             但我们建议客户在其原始实物交割时清算其对冲头寸\r\n                             职位也已被清除。",
  "hedging_msg_12": "图一︰要全面对冲价位风险所需之手数对算表",
  "gf_gold_title": "与 GF Gold 一起塑造您的未来",
  "gf_gold_msg_1": "事半功倍",
  "gf_gold_msg_2": "投资杠杆金/白银合约",
  "gf_gold_msg_3": "最低保证金要求仅 2%",
  "gf_gold_msg_4": "随时随地交易",
  "gf_gold_msg_5": "交易日24小时交易抓住每一个机会",
  "gf_gold_msg_6": "极低的交易费用",
  "gf_gold_msg_7": "达到特定交易量免佣金",
  "gf_gold_msg_8": "现金或实物结算",
  "gf_gold_msg_9": "现金结算，或全额付款后选择实体黄金结算",
  "gf_gold_msg_10": "短期投资或实物对冲的理想选择",
  "seo_1": "金丰来有限公司(Gold Fun Corporation Ltd),成立于2016年,是香港金银业贸易场正式行员(行员编号:064,营业状况类别:B),亦是经香港海关注册的 A 类注册商。一直专注于贵金属业务,我们提供一站式全方位服务,包括黄金的精炼、生产、黄金贸易、黄金投资,以及市场行销及价位对冲等。从金矿源头到纯金制品,从押运仓存到投资对冲,我们的专业团队及顾问皆能为客户量身订做各类贵金属贸易及投资方案,安全可靠。",
  "seo_2": "香港顶级黄金交易平台",
  "seo_3": "透过我们简单易用的网路平台，客户可以轻松买卖及交易黄金。它提供先进的图表工具和即时市场数据。\r",
  "about_us_title": "关于金丰来GoldFun - 香港黄金交易经纪商",
  "about_us_p1": "金丰来有限公司(Gold Fun Corporation Ltd),成立于2016年,是香港金银业贸易场正式行员(行员编号:064,营业状况类别:B),亦是经香港海关注册的 A 类注册商。一直专注于贵金属业务,我们提供一站式全方位服务,包括黄金的精炼、生产、黄金贸易、黄金投资,以及市场行销及价位对冲等。从金矿源头到纯金制品,从押运仓存到投资对冲,我们的专业团队及顾问皆能为客户量身订做各类贵金属贸易及投资方案,安全可靠。\r",
  "about_us_p2": "近年来,我们在香港与多家领先的熔炼服务公司建立业务合作,日均处理量大增,交收快捷妥当。展望未来,金丰来会根据市场最新趋势,致力于改善业务结构并把握新兴产业的机遇。我们积极拓展本地及国际市场,寻找可靠的合作伙伴,全面推动公司发展。我们的团队以诚实、积极和专业的态度,为客户提供稳定、高效且快速的服务,应对日新月异的国际贵金属市场。\r",
  "about_us_p3": "使命宣言",
  "about_us_p4": " 在GoldFun，我们的目标是让机构和个人轻松存取透明的黄金交易平台，以便他们能够在不断变化的金融环境中保护和增加自己的财富。\n",
  "about_us_p5": "储存解决方案",
  "about_us_p6": "GoldFun 与知名储存设施合作，为客户的实体黄金持有提供安全且有保险的储存选项，确保安心并防止盗窃或遗失。",
  "about_us_p7": "香港顶级黄金交易平台",
  "about_us_p8": "透过我们简单易用的网路平台，客户可以轻松买卖及交易黄金。它提供先进的图表工具和即时市场数据。\r",
  "about_us_p9": "个人化支援",
  "about_us_p10": "我们经验丰富的专家团队致力于为每位客户提供个人化的关注。我们透过提供投资组合分析、客制化投资建议和指导来帮助客户最大限度地发挥其黄金交易技巧来做到这一点。",
  "about_us_p11": "追求卓越",
  "about_us_p12": "在我们所有的互动中，GoldFun 都坚持诚实、开放和能力的最高标准。我们的使命是在相互尊重、信任和共同渴望财务成功的基础上与客户建立长期关系。",
  "about_us_p13": "立即加入 GoldFun，开始探索黄金交易的经济效益。\r",
  "risk_closure": "风险声明：贵金属交易会为您的资金带来很高的风险，您应该只用您可以承受损失的资金进行交易。贵金属交易可能不适合所有投资者，因此请确保您完全了解所涉及的风险，并在必要时寻求独立建议。请阅读并确保您完全理解我们的风险声明。"
};