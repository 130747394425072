import { createI18n } from 'vue-i18n';

function loadLocaleMessages() {
    const contexts = require.context(
        './locales', // The path to the locales directory
        true, // Whether to look in subdirectories
        /[A-Za-z0-9-_,\s]+\.js$/i // The regex pattern for .js locale files
      );
    
      const messages = {};
      contexts.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          const locale = matched[1];
          messages[locale] = contexts(key).default;
        }
      });
    
      return messages;
}

function createMyI18n(defaultLocale) {
  return createI18n({
    legacy: false,
    locale: defaultLocale,
    fallbackLocale: 'en-US',
    messages: loadLocaleMessages(),
  });
}

export { loadLocaleMessages, createMyI18n };
