import { reactive } from 'vue';
import apiServices from '../services/apiServices';

export const globalState = reactive({
    isAuth: false,
    user: null,
    isLoading: false,
    screenWidth : 0,
    articleId: 0,
    isMenuOpen: false,
});

// Function to update authentication status
export function setAuthStatus(status) {
    globalState.isAuth = status;
}

// Function to log out
export async function logout() {

    try {
        globalState.isLoading = true;

        if (localStorage.getItem('token')) {

            const resp = await apiServices.logout();
            if (resp.data.code == 200) {
                localStorage.removeItem('token');
                setAuthStatus(false);
                globalState.user = null;
                window.location.href = '/';
            }
        }
        globalState.isLoading = false;

    } catch (error) {
        console.error('Error during logout:', error);
        globalState.isLoading = false;
    }
}

export async function getBalance() {

    if (globalState.user) {
        const memberWalletRes = await apiServices.getMemberWalletById({ id: globalState.user.id });
        globalState.balance = memberWalletRes.data.walletBalance;
    }

}


