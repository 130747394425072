import { createApp } from 'vue';
import App from './App.vue';
import router from './Router/index';
import { createMyI18n } from './i18n/index';
import redirectTo from './services/generalFunction';
import { useLanguageStore } from './store/langStore';
import { watch } from 'vue';
import { createPinia } from 'pinia';
import { globalState } from './store/globalState';
import { showNotification } from './store/notificationStore';
import { notificationSymbol } from './tools/symbol';
// import VueGtag from 'vue-gtag'



import 'swiper/css';
function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context('./assets/css', false, /\.css$/));
importAll(require.context('./assets/css/color-scheme', false, /\.css$/));



const pinia = createPinia();

function notify(msg, type) {
  showNotification(msg, type);
}



function init() {
  const app = createApp(App);

  app.use(pinia);
  // Provide the notification function
  app.provide(notificationSymbol, notify);

  const languageStore = useLanguageStore();

  if (!localStorage.getItem('lang')) {
    languageStore.setLanguage('en-US');
  }

  const i18n = createMyI18n(languageStore.currentLanguage);

  app.use(router);
  app.use(i18n);

  // app.use(VueGtag, {
  //   config: { id: 'G-0B0HXY1Y5X' }
  // });

  app.config.globalProperties.$redirectTo = redirectTo;
  app.config.globalProperties.$globalState = globalState;

  app.config.globalProperties.$changeLang = (lang) => {
    i18n.global.locale.value = lang;
    languageStore.setLanguage(lang);
  };


  watch(() => languageStore.currentLanguage, (newLocale) => {
    i18n.global.locale.value = newLocale;
  }, { immediate: true });

  app.mount('#app');
}

init();
