import { reactive } from 'vue';

export const notificationState = reactive({
  message: '',
  type: '',
  visible: false,
  timeoutId: null
});

export function showNotification(msg, type) {
  if (notificationState.timeoutId) {
    clearTimeout(notificationState.timeoutId);
  }
  
  notificationState.message = msg;
  notificationState.type = type;
  notificationState.visible = true;

  notificationState.timeoutId = setTimeout(() => {
    notificationState.visible = false;
    notificationState.timeoutId = null; 
  }, 3000); 
}

export function hideNotification() {
  clearTimeout(notificationState.timeoutId);
  notificationState.visible = false;
  notificationState.timeoutId = null;
}
