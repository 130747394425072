import { defineStore } from 'pinia';

export const useLanguageStore = defineStore({
  id: 'language',
  state: () => ({
    currentLanguage: localStorage.getItem('lang') || 'en', // Load from localStorage or default to 'en'
  }),
  actions: {
    setLanguage(newLanguage) {
      this.currentLanguage = newLanguage;
      localStorage.setItem('lang', newLanguage); // Save the selected language to localStorage
    },
  },
});
