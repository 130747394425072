import axios from 'axios';
import { globalState, logout } from '../store/globalState';

function getAuthToken() {
    return localStorage.getItem('token');
}

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: false,
});

const apiClientBypass = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: false,
});


// Add a request interceptor
apiClientBypass.interceptors.request.use(function (config) {

    const token = getAuthToken();
    config.headers['key'] = token ? token : '';
    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a request interceptor
apiClient.interceptors.request.use(function (config) {
    const token = getAuthToken();
    globalState.isLoading = true; // Start loading
    config.headers['key'] = token ? token : '';
    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/json';
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});



apiClient.interceptors.response.use(
    
    response => {
        // Stop loading
        globalState.isLoading = false;
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        return response;
    },
    error => {
        // Stop loading on error
        globalState.isLoading = false;

        if (error.response.status === 401) {
            logout();
        }

        return Promise.reject(error);
    }
);


export default {
   
};
